import React from 'react';
import { Container, Typography, Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Form from './Form';
import image from '../assets/screenshot.jpg';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
  },
  bungeeWhite: {
    fontFamily: '"Bungee Shade", cursive',
    color: '#ffffff',
    fontWeight: 'bold',
    userSelect: 'none',
  },
  bungeeGold: {
    fontFamily: '"Bungee Shade", cursive',
    color: '#ffc300',
    fontWeight: 'bold',
    userSelect: 'none',
  },
  uppercaseRoboto: {
    color: '#ffffff',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  message: {
    marginBottom: 32,
    color: '#ffffff',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    userSelect: 'none',
  },
  caption: {
    marginBottom: 32,
    marginTop: 32,
    color: '#ffffff',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    userSelect: 'none',
  },
  imageRoot: {
    position: 'relative',
  },
  arrow: {
    borderWidth: '0 4px 0 0',
    border: 'dashed red',
    borderRadius: '50%',
    width: 150,
    height: 200,
    position: 'absolute',
    zIndex: -1,
    top: '80%',
    right: '25%',
  },
  arrowEnd: {
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '20px solid red',
    position: 'absolute',
    top: '95%',
    right: '30%',
    transform: 'rotate(57deg)',
  },
  image: {
    width: '50%',
    height: 'auto',
    transform: 'rotate(-30deg)',
    zIndex: 1,
  },
});

const Landing = ({ firebase }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <Box py={5}>
          <Typography variant={'h1'} gutterBottom className={classes.bungeeWhite}>
            Social Gloves
          </Typography>
        </Box>
        <Box py={5}>
          <Typography variant={'h3'} className={classes.message}>
            FOR THOSE WHO PURCHASED THE YOUTUBE VS. TIKTOK PPV, UPLOAD YOUR RECEIPT (FROM YOUR
            EMAIL) BELOW FOR THE CHANCE TO BE ONE OF THE HANDFUL TO WIN
          </Typography>
          <Typography variant={'h1'} component={'p'} className={classes.bungeeGold}>
            $20,000
          </Typography>
          <Typography variant={'body1'} component={'p'} className={classes.caption}>
            * Only one submission allowed per PPV purchase
          </Typography>
        </Box>
        <Box pt={5} pb={15}>
          <Grid container alignItems={'center'}>
            <Grid item xs={12} md={6}>
              <Typography variant={'h3'} className={classes.message}>
                TAKE A SCREENSHOT OF YOUR RECEIPT SENT TO YOUR EMAIL
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.imageRoot}>
              <img src={image} alt={'SG'} className={classes.image} />
              <div className={classes.arrow}>
                <div className={classes.arrowEnd} />
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box py={5}>
          <Form firebase={firebase} />
        </Box>
      </Container>
    </div>
  );
};

export default Landing;
