import { v4 } from 'uuid';

const getTextWithoutSpaces = (string) => string.replace(/\s/g, '');

const isEmptyString = (string) => getTextWithoutSpaces(string).length === 0;

const isValidCheckOutFormData = (model) => {
  const errors = [];

  if (isEmptyString(model.firstName)) errors.push('firstName');
  if (isEmptyString(model.lastName)) errors.push('lastName');
  if (isEmptyString(model.email)) errors.push('email');
  if (isEmptyString(model.imageName)) errors.push('imageName');

  console.log(errors);
  return errors.length === 0;
};

const getFileWithUniqueName = (file) =>
  new File([file], `${v4()}.${file.name.split('.')[1]}`, { type: file.type });

export { isEmptyString, getTextWithoutSpaces, getFileWithUniqueName, isValidCheckOutFormData };
