import React from 'react';
import { Card, CardActionArea, CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UploadIcon from './UploadIcon';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  input: {
    visibility: 'hidden',
    width: 0,
    height: 0,
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    border: `2px dashed ${theme.palette.common.white}`,
    width: '100%',
    height: 235,
    background: 'transparent',
    borderRadius: 0,
  },
  error: {
    border: `2px dashed ${theme.palette.error.main}`,
  },
  image: {
    width: '100%',
    height: 235,
    objectFit: 'cover',
  },
  actionArea: {
    position: 'relative',
    color: theme.palette.common.white,
    width: '100%',
    height: 235,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.2)',
      cursor: 'pointer',
    },
  },
  icon: {
    color: theme.palette.common.white,
    width: 60,
    height: 60,
  },
  centered: {
    position: 'absolute',
    top: 'calc(50% - 30px)',
    left: 'calc(50% - 30px)',
  },
  disabled: {
    backgroundColor: 'rgba(255,255,255,0.3)',
  },
}));

const ImageInput = (props) => {
  const { isUploading, isError, acceptedFiles, link, handleFileSelect, inputValue } = props;
  const classes = useStyles();

  return (
    <>
      <Card
        className={clsx({
          [classes.root]: true,
          [classes.error]: isError,
          [classes.disabled]: isUploading,
        })}
      >
        <Tooltip title={'Click to select file on your computer'}>
          <CardActionArea
            component="label"
            className={classes.actionArea}
            htmlFor={'file-upload'}
            disabled={isUploading}
          >
            {link && <img src={link} alt={'Social Gloves'} className={classes.image} />}
            <span className={classes.centered}>
              {!isUploading && <UploadIcon className={classes.icon} />}
              {isUploading && <CircularProgress size={40} />}
            </span>
          </CardActionArea>
        </Tooltip>
        <input
          type="file"
          disabled={isUploading}
          className={classes.input}
          onChange={handleFileSelect}
          id={`file-upload`}
          name={`file-upload`}
          value={inputValue}
          accept={acceptedFiles}
        />
      </Card>
      {isError && (
        <Typography variant={'caption'} color={'primary'}>
          Image Is Required
        </Typography>
      )}
    </>
  );
};

export default ImageInput;
