import React from 'react';
import { Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    '& .MuiPaper-root': {
      color: '#ffffff',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: 24,
      background: '#14840b',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
});

const Notification = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      message={'Your receipt has successfully been uploaded'}
      classes={{
        root: classes.root,
      }}
    />
  );
};

export default Notification;
