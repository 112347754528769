import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/analytics';
import 'firebase/storage';
import React, { useCallback, useEffect } from 'react';
import Landing from './components/Landing';

function App() {
  const initFirebase = useCallback(() => {
    const firebaseConfig = {
      apiKey: 'AIzaSyAaSSQg3Y1NnrqExLahX3iTfLyK20GavUE',
      authDomain: 'social-gloves.firebaseapp.com',
      projectId: 'social-gloves',
      storageBucket: 'social-gloves.appspot.com',
      messagingSenderId: '949731028712',
      appId: '1:949731028712:web:e8e3751133d75f6fa3635d',
      measurementId: 'G-WYF7HHTS9D',
      databaseURL: 'https://socialglovespromo-449d4.firebaseio.com/',
    };

    firebase.initializeApp(firebaseConfig);
    firebase.analytics().setAnalyticsCollectionEnabled(true);
  }, []);

  useEffect(() => {
    initFirebase();
  }, [initFirebase]);

  return (
    <div className="App">
      <Landing firebase={firebase} />
    </div>
  );
}

export default App;
