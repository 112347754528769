import React, { useState } from 'react';
import { Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import { getFileWithUniqueName, isEmptyString, isValidCheckOutFormData } from '../helpers/common';
import ImageInput from './ImageInput';
import { v4 } from 'uuid';
import Notification from './Notification';

const initialModel = {
  email: '',
  firstName: '',
  lastName: '',
  imageUrl: '',
  imageName: '',
};

const Form = ({ firebase }) => {
  const [model, setModel] = useState(initialModel);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [fileForUpload, setFileForUpload] = useState(null);
  const [filePreview, setFilePreview] = useState('');
  const [fileInputValue, setFileInputValue] = useState('');
  const [isSuccessMessageOpen, setIsSuccessMessageOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsSuccessMessageOpen(false);
  };

  const handleModelChange = (key, e) => {
    setModel((prevState) => ({
      ...prevState,
      [key]: e.target.value,
    }));
  };

  const handleFileSelect = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      const uploadFile = getFileWithUniqueName(file);

      setModel((prevState) => ({ ...prevState, imageName: file.name }));
      setFilePreview(URL.createObjectURL(uploadFile));
      setFileForUpload(uploadFile);
    }
  };

  const handleSubmit = async () => {
    if (isError) {
      setIsError(false);
    }
    setIsLoading(true);

    if (isValidCheckOutFormData(model)) {
      const database = firebase.database();
      const storage = firebase.storage();

      const ref = storage.ref(`/images/${fileForUpload.name}`);
      const uploadTask = ref.put(fileForUpload);

      uploadTask.on('state_changed', console.log, console.error, () => {
        ref
          .getDownloadURL()
          .then((url) => {
            database.ref('subscribed_users/' + v4()).set({ ...model, imageUrl: url });
          })
          .then(() => {
            setModel(initialModel);
            setFileForUpload(null);
            setFileInputValue('');
            setFilePreview('');
            setIsLoading(false);
            setIsSuccessMessageOpen(true);
          });
      });
    } else {
      setIsError(true);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Notification open={isSuccessMessageOpen} onClose={handleClose} />
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                required
                id="email"
                disabled={isLoading}
                color="secondary"
                type="email"
                label="Email Address"
                placeholder="Email Address"
                value={model.email}
                onChange={(e) => handleModelChange('email', e)}
                error={isError && isEmptyString(model.email)}
                helperText={isError && isEmptyString(model.email) && 'Email is required'}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                disabled={isLoading}
                color="secondary"
                id="first-name"
                label="First Name"
                placeholder="First Name"
                value={model.firstName}
                onChange={(e) => handleModelChange('firstName', e)}
                error={isError && isEmptyString(model.firstName)}
                helperText={isError && isEmptyString(model.firstName) && 'First Name is required'}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                disabled={isLoading}
                color="secondary"
                id="last-name"
                label="Last Name"
                placeholder="Last Name"
                value={model.lastName}
                onChange={(e) => handleModelChange('lastName', e)}
                error={isError && isEmptyString(model.lastName)}
                helperText={isError && isEmptyString(model.lastName) && 'Last Name is required'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <ImageInput
            isUploading={isLoading}
            isError={isError && isEmptyString(model.imageName)}
            acceptedFiles="image/*, application/pdf"
            link={filePreview}
            handleFileSelect={handleFileSelect}
            inputValue={fileInputValue}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={handleSubmit}
            color="primary"
            size="large"
            variant="contained"
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress size={20} color={'inherit'} /> : null}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Form;
