import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { colors, createMuiTheme, CssBaseline, MuiThemeProvider } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: colors.red,
    secondary: colors.red,
    background: {
      default: '#000',
    },
  },
  typography: {
    fontFamily: '"Roboto", sans-serif',
    h1: {
      fontSize: '4rem',
      '@media (min-width: 600px)': {
        fontSize: '5rem',
      },
      '@media (min-width: 960px)': {
        fontSize: '6rem',
      },
    },
    h2: {
      fontSize: '2.25rem',
      '@media (min-width: 600px)': {
        fontSize: '3rem',
      },
      '@media (min-width: 960px)': {
        fontSize: '3.75rem',
      },
    },
    h3: {
      fontSize: '2rem',
      '@media (min-width: 600px)': {
        fontSize: '3rem',
      },
    },
    h4: {
      fontSize: '1.75rem',
      '@media (min-width: 600px)': {
        fontSize: '2.125rem',
      },
    },
    h6: {
      fontSize: '1em',
      '@media (min-width: 600px)': {
        fontSize: '1.25rem',
      },
    },
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
      fullWidth: true,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          '@media (max-width: 599px)': {
            fontSize: 14,
          },
        },
      },
    },
    MuiButton: {
      label: {
        fontWeight: 'bold',
      },
      containedSizeLarge: {
        padding: '12px 40px',
        fontSize: 20,
        borderRadius: 0,
      },
      disabled: {},
      contained: {
        '&$disabled': {
          color: '#fff',
          backgroundColor: colors.red['900'],
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: '#fff',
          color: '#fff',
        },
      },
      notchedOutline: {},
      colorSecondary: {
        '& .MuiInputBase-root': {
          color: '#fff',
        },
        '& $notchedOutline': {
          borderColor: '#fff',
        },
        '&.Mui-focused': {
          borderColor: colors.red['500'],
          '&:hover $notchedOutline': {
            borderColor: colors.red['500'],
          },
        },
        '&:hover $notchedOutline': {
          borderColor: '#fff',
          borderWidth: 2,
        },
      },
    },
    MuiInputBase: {
      root: {
        '&.Mui-disabled': {
          color: '#fff',
        },
      },
      colorSecondary: {
        color: '#fff',
      },
    },
    MuiFormLabel: {
      root: {},
      colorSecondary: {
        '&$root': {
          color: '#fff',
        },
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
